<div class="footer">
    <div class="container row">
        <div class="footerP1 col-sm-12 col-md-5">
            <div>
                <img loading="lazy" [routerLink]="['/']" src="assets/images/logo.webp" alt="" style="cursor: pointer;" class="logo">
                <div class="d-flex justify-content-center">
                    <div (click)="face()" class="iconDiv"><ion-icon name="logo-facebook"></ion-icon></div>
                    <div (click)="linkedin()" class="iconDiv"><ion-icon name="logo-linkedin"></ion-icon></div>
                    <div (click)="insta()" class="iconDiv"><ion-icon name="logo-instagram"></ion-icon></div>
                    <div (click)="whats()" class="iconDiv"><ion-icon name="logo-whatsapp"></ion-icon></div>
                    <div (click)="twitter()" class="iconDiv"><ion-icon name="logo-twitter"></ion-icon></div>
                </div>
            </div>
        </div>
        <div class="footerP2 col-sm-12 col-md-7">
            <div>
                <span class="bottomLinksAll">
                    <span [routerLink]="['/sitemap']" class="bottomLinks blueHover">Sitemap</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" (click)="goPage(5)">Contato</span>
                    <span class="bottomLinksDiv">|</span>
                    <span class="bottomLinks blueHover" (click)="policy()">Política de Privacidade</span>
                    <span class="bottomLinksDiv">|</span>
                    <span [routerLink]="['/termos-e-condicoes']" class="bottomLinks blueHover">Termos de Uso</span>
                </span>
                <div class="copyright">QUALITYMANAGER ® - TODOS OS DIREITOS RESERVADOS - 2005 - {{thisYear}}</div>
                <div class="inpi">QualityManager® é <b (click)="marca()" style="color: white; cursor: pointer;" class="blueHover">marca registrada</b> no INPI - Instituto Nacional da Propriedade Industrial - sob o nº 901307823.</div>
                <div class="iqa"><span (click)="iqa()" class="blueHover">Certificado de Conformidade baseado na ABNT NBR ISO/IEC 29110-4-1:2020, emitido pelo IQA.</span></div>
                <div class="fy"><span (click)="webFy()" class="blueHover">Powered by MyWebFy</span></div>
            </div>
        </div>
    </div>
</div>
