<div class="page">
    <app-header></app-header>
    <div class="headerHeight"></div>

    <!-- part 1 -->
    <div class="content container">
        <div style="height: 1px;"></div>

        <span class="p2Title">Planos</span>
        <div class="w-100 d-flex justify-content-center" style="padding-bottom: 15px; padding-top: 10px;">
            <img loading="lazy" src="assets/icon/div.webp" style="height: 12px;" alt="">
        </div>
        <span class="p2SubTitle container">Conheça nossos planos completos e escolha a opção que melhor se adapta a sua empresa</span>

        <table class="price-table mt-5">
            <tbody>
                <tr>
                    <!-- <td class="price-blank"></td> -->
                    <td class="price-blank"></td>
                    <td class="price-blank"></td>
                    <td class="price-table-popular">Popular</td>
                    <td class="price-blank"></td>
                </tr>
                <tr class="price-table-head">
                    <td></td>
                    <!-- <td>
                        Startup
                        <br><small style="font-size: 12px; font-weight: 400;">Para conhecer o sistema</small>
                    </td> -->
                    <td>
                        Business
                        <br><small style="font-size: 12px; font-weight: 400;">Para pequenas empresas</small>
                    </td>
                    <td>
                        Premium
                        <br><small style="font-size: 12px; font-weight: 400;">Para médias empresas</small>
                    </td>
                    <td class="green-width">
                        Enterprise
                        <br><small style="font-size: 12px; font-weight: 400;">A solução completa</small>
                    </td>
                </tr>
                <tr>
                    <td></td>
                                    <!-- <td class="price">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="-50 -50 256 256">
                                            <defs/>
                                            <path d="M83.787,97.689c-6.165-4.892-33.436-11.621-27.74-23.522c0.491-1.026-0.879-1.834-1.525-0.892
                                                c-4.465,6.51-0.326,12.881,5.866,16.441c7.073,4.067,20.452,4.547,24.773,12.671c6.476,12.176-4.377,32.637-18.038,35.035
                                                c-0.948,0.166-0.741,1.814,0.23,1.698c11.747-1.407,21.576-15.549,23.592-26.586C92.217,105.567,89.195,101.98,83.787,97.689z"/>
                                            <path d="M125.368,7.265c1.941-2.069,1.004-5.756-1.727-6.854c-3.628-1.459-6.698,1.195-7.983,4.373
                                                c-4.389-0.788-8.99-1.23-13.85-1.203c-0.746,0.004-1.124,0.571-1.149,1.152c-1.826,1.027-1.571,5.01-0.527,6.427
                                                c0.203,0.275,0.489,0.368,0.778,0.388c0.124,0.104,0.272,0.187,0.458,0.225c3.7,0.745,7.203,1.745,10.614,2.858
                                                c-1.618,3.992-3.34,7.879-5.466,11.633c-1.931-0.428-3.897-0.805-5.946-1.062c-31.338-3.929-64.733,13.773-73.069,44.347
                                                c-0.13-0.01-0.261-0.017-0.391-0.027c-0.423-0.033-0.407,0.602,0,0.651c0.064,0.007,0.127,0.017,0.191,0.025
                                                c-0.596,2.299-1.082,4.652-1.384,7.09c-0.194,1.57-0.327,3.15-0.413,4.736c-0.13-0.009-0.265-0.014-0.394-0.024
                                                c-0.741-0.059-0.734,1.091,0,1.153c0.119,0.01,0.241,0.026,0.361,0.037c-0.177,4.637,0.062,9.318,0.716,13.961
                                                c-0.738,0.255-0.689,1.465,0.225,1.51c3.856,24.067,18.714,46.68,41.561,54.861c-0.41,0.953-0.765,1.94-1.185,2.845
                                                c-0.896,1.932-1.896,3.798-2.975,5.633c-3.01-0.807-6.013-1.72-9-2.745c-0.533-0.183-0.854,0.173-0.914,0.594
                                                c-2.256,0.849-3.903,3.648-2.379,5.845c0.484,0.697,1.891,0.55,1.784-0.483c-0.162-1.564,0.028-3.234,1.781-3.767
                                                c0.169-0.051,0.233-0.171,0.335-0.267c49.671,19.923,104.973,3.117,112.991-54.322c6.34-45.415-17.952-91.22-66.65-97.461
                                                c-0.071-0.718-0.048-1.435,0.268-2.119c0.228-0.495,0.457-0.853,0.59-1.273c57.448-0.066,79.837,63.642,67.486,111.824
                                                c-12.994,50.693-62.723,68.631-108.453,51.998c-0.123-0.341-0.539-0.497-0.875-0.335c-2.526-0.944-5.042-1.969-7.535-3.125
                                                c-0.855-0.396-1.587,0.784-0.751,1.284c2.311,1.382,4.709,2.596,7.149,3.714c-1.81,3.325-3.423,6.794-4.838,10.292
                                                c-3.942-0.26-8.204,0.936-11.057,3.631c-2.622,2.477-4.617,7.061-2.363,10.367c0.418,0.614,1.473,0.452,1.518-0.344
                                                c4.183,6.014,13.349,4.707,20.427,5.006c20.088,0.848,40.217,0.532,60.282-0.707c0.583-0.036,0.954-0.378,1.141-0.8
                                                c5.941-2.306,7.256-10.191,2.511-14.71c-0.4-0.381-0.853-0.448-1.267-0.351c-0.103-0.036-0.157-0.128-0.282-0.134
                                                c-21.227-1.007-42.309,1.604-63.48-0.967c1.798-2.669,3.395-5.493,5.001-8.285c20.029,6.745,42.886,5.666,62.586-1.729
                                                c23.272-8.737,37.025-30.851,42.987-54.062C183.96,75.144,167.801,21.569,125.368,7.265z M66.784,149.232
                                                c5.08,0.263,10.033,1.115,15.031,2.015c0.626,0.113,0.898-0.787,0.267-0.968c-4.984-1.426-10.16-1.943-15.329-2.11
                                                c-0.498-0.016-0.62,0.531-0.393,0.845c-3.111-1.613-6.114-3.339-8.905-5.271c7.444,1.491,14.98,2.594,22.509,3.532
                                                c0.506,0.063,0.625-0.803,0.121-0.895c-7.902-1.445-15.871-2.746-23.868-3.551c-0.032-0.003-0.049,0.021-0.077,0.026
                                                c-3.811-2.759-7.266-5.845-10.374-9.208c4.063,0.889,8.128,1.781,12.228,2.468c0.739,0.124,1.073-0.958,0.316-1.148
                                                c-4.653-1.169-9.374-2.068-14.08-3.012c-1.604-1.86-3.062-3.831-4.443-5.858c3.67,0.579,7.413,0.928,11.104,1.173
                                                c0.453,0.03,0.568-0.735,0.109-0.803c-3.891-0.582-7.858-1.093-11.797-1.284c-3.301-5.021-5.879-10.551-7.737-16.508
                                                c4.008,0.465,7.986,1.047,11.983,1.663c0.726,0.112,1.056-0.921,0.311-1.13c-4.139-1.158-8.472-1.784-12.778-2.074
                                                c-0.757-2.65-1.316-5.414-1.771-8.234c3.887,0.285,7.738,0.822,11.568,1.603c0.931,0.19,1.322-1.161,0.396-1.434
                                                c-4.009-1.185-8.098-1.738-12.245-1.929c-0.219-1.601-0.345-3.251-0.468-4.901c0.082,0.135,0.21,0.248,0.425,0.285
                                                c2.7,0.463,5.399,1.006,8.123,1.305c0.752,0.083,0.906-1.148,0.178-1.315c-2.609-0.596-5.277-0.953-7.916-1.389
                                                c-0.443-0.073-0.756,0.222-0.863,0.559c-0.172-2.547-0.26-5.138-0.17-7.815c0.004-0.133,0.023-0.26,0.028-0.393
                                                c2.038,0.226,4.097,0.427,6.088,0.273c0.447-0.035,0.595-0.703,0.111-0.822c-1.962-0.483-4.042-0.622-6.107-0.733
                                                c0.141-2.265,0.445-4.457,0.836-6.607c1.941,0.066,3.881,0.131,5.823,0.048c0.347-0.015,0.347-0.524,0-0.539
                                                c-1.916-0.082-3.831-0.019-5.746,0.046c0.297-1.558,0.717-3.052,1.136-4.547c2.193,0.29,4.388,0.575,6.584,0.74
                                                c0.667,0.05,0.637-0.944,0-1.023c-2.112-0.259-4.244-0.409-6.376-0.55c0.447-1.502,0.958-2.968,1.52-4.404
                                                c2.724,0.047,5.431,0.147,8.157,0.295c0.848,0.046,1.052-1.333,0.203-1.497c-2.497-0.484-5.072-0.581-7.625-0.469
                                                c0.555-1.287,1.217-2.505,1.859-3.735c1.853,0.032,3.583,0.513,5.259,1.411c0.667,0.358,1.223-0.601,0.591-1.011
                                                c-1.617-1.051-3.39-1.551-5.25-1.605c1.104-1.995,2.302-3.925,3.656-5.739c8.037,12.285-3.398,28.202,3.12,41.625
                                                c7.489,15.421,16.508,27.094,19.672,44.7c0.258,1.438,2.379,0.808,2.17-0.598c-1.104-7.392-2.815-14.376-5.713-21.292
                                                c-3.268-7.801-10.075-14.326-13.021-21.986c-5.409-14.061,4.706-31.85-5.275-43.623c9.486-12.006,23.804-19.982,39.48-22.667
                                                c-0.075,0.298-0.081,0.623,0.066,0.963c5.231,12.119,3.008,25.396-9,32.073c-4.984,2.771-9.688,4.422-13.242,9.075
                                                c-0.342,0.448,0.394,0.995,0.774,0.597c7.208-7.56,18.345-6.915,23.99-16.581c4.832-8.273,4.178-18.177-0.046-26.508
                                                c6.013-0.802,12.191-0.85,18.353-0.026c8.997,1.204,16.757,4.173,23.322,8.465c-4.079,0.614-8.163,3.645-10.438,6.579
                                                c-0.532,0.687,0.359,1.663,0.986,0.986c1.793-1.933,3.93-3.429,6.424-4.32c2.019-0.721,4.073-0.684,6.118-1.069
                                                c7.335,5.598,12.976,12.982,16.939,21.471c-9.627,1.022-44.335,2.917-32.967-13.224c0.536-0.761-0.638-1.623-1.27-0.98
                                                c-4.355,4.426-3.509,11.939,2.22,14.822c9.099,4.579,23.03,2.92,32.8,1.199c1.459,3.38,2.691,6.903,3.64,10.563
                                                c-13.275-0.6-28.621-12.6-41.554-4.411c-7.521,4.763-6.066,15.14,1.339,19.441c0.632,0.367,1.288-0.484,0.777-1.007
                                                c-6.874-7.045-3.697-17.893,6.925-18.743c4.479-0.359,8.803,0.818,13.031,2.099c6.692,2.028,12.854,4.283,19.9,4.359
                                                c1.513,6.435,2.302,13.19,2.277,20.092c-0.046,12.884-6.179,25.337-15.489,35.502c-12.857-1.064-5.757-21.913-8.664-29.985
                                                c-2.342-6.504-7.932-7.424-13.315-10.684c-0.825-0.499-1.857,0.751-1.059,1.372c4.501,3.502,9.804,4.157,12.24,10.076
                                                c2.477,6.02,0.005,12.615,0.484,18.846c0.438,5.699,3.036,11.324,8.843,11.935c-14.198,14.626-34.987,24.06-53.147,22.278
                                                c-0.403-0.04-0.7,0.081-0.934,0.266c-3.184-1.035-6.216-2.252-9.16-3.567c-0.134-0.329-0.524-0.503-0.854-0.376
                                                C68.714,150.281,67.769,149.735,66.784,149.232z M70.038,163.486c-1.707-0.37-3.414-0.764-5.12-1.206
                                                c1.257-1.744,2.47-3.535,3.513-5.414c0.475-0.855,1.011-1.776,1.442-2.728c1.305,0.414,2.636,0.777,3.98,1.114
                                                c-0.417,1.047-0.729,2.165-1.18,3.162c-0.72,1.589-1.583,3.094-2.54,4.551C70.01,163.153,70.015,163.324,70.038,163.486z
                                                 M165.532,105.924c-6.992,51.543-50.053,66.833-93.97,57.875c1.179-1.586,2.235-3.244,3.12-5.013
                                                c0.48-0.96,1.011-2.002,1.327-3.072c1.095,0.227,2.184,0.468,3.316,0.616c0.176,0.556,0.626,1.036,1.353,1.108
                                                c31.669,3.107,71.682-26.396,73.129-59.473c1.327-30.315-11.978-59.396-39.796-69.515c1.081-1.72,2.156-3.442,3.174-5.202
                                                c1.017-1.759,2.207-3.555,2.971-5.463C154.045,32.756,170.827,66.893,165.532,105.924z M118.507,17.135
                                                c-1.134,1.614-2.042,3.434-2.954,5.158c-0.997,1.885-1.932,3.798-2.854,5.719c-1.594-0.537-3.208-1.048-4.894-1.46
                                                c2.318-3.564,4.281-7.381,5.594-11.428C115.156,15.73,116.83,16.435,118.507,17.135z M123.55,6.314
                                                c-0.111,0.08-0.121,0.194-0.187,0.292c-2.06-0.622-4.176-1.155-6.355-1.59c0.915-1.686,2.336-3.216,4.347-3.285
                                                C123.805,1.648,125.997,4.559,123.55,6.314z M125.008,185.813c0.064,0.108,0.112,0.218,0.214,0.32
                                                c3.448,3.432,2.51,8.456-1.35,10.833c-0.089-0.012-0.163-0.044-0.264-0.038c-12.047,0.694-24.093,1.221-36.161,1.301
                                                c-7.83,0.051-35.51,2.461-43.103-3.881c6.14,0.372,12.293,0.529,18.442,0.618c0.782,0.011,0.773-1.166,0-1.209
                                                c-6.383-0.348-12.777-0.636-19.171-0.67c-0.132-0.001-0.208,0.067-0.295,0.121c-0.098-0.136-0.247-0.251-0.329-0.393
                                                c0.111-1.118,0.387-2.157,0.833-3.127c9.842,0.264,19.541,0.789,29.306,2.22c1.261,0.185,1.512-1.563,0.235-1.735
                                                c-9.525-1.279-19.19-2.01-28.809-1.71c0.503-0.712,1.099-1.39,1.924-1.989c2.566-1.864,5.579-2.311,8.532-3.097
                                                c-0.145,1.508,0.534,2.968,2.216,3.456c1.923,0.558,4.136-0.769,4.234-2.776C82.425,188.124,103.807,185.194,125.008,185.813z
                                                 M59.667,182.446c-0.347,0.547-0.162,1.047,0.213,1.352c-0.438,0.596-0.957,1.075-1.772,1.012
                                                c-0.935-0.073-1.584-0.945-1.593-1.909c0.326-0.221,0.422-0.695,0.171-0.97c1.597-3.22,3.058-6.557,4.297-9.943
                                                c1.626,0.707,3.283,1.339,4.954,1.933C63.778,176.706,61.551,179.477,59.667,182.446z"/>
                                            <path d="M47.715,117.839c-3.985-0.66-8.006-1.01-12.023-1.426c-0.649-0.067-0.626,0.921,0,1.004
                                                c3.958,0.528,7.905,1.123,11.889,1.408C48.139,118.865,48.276,117.932,47.715,117.839z"/>
                                        </svg>
                                        <br>
                                        <br>
                                    </td> -->
                                    <td class="price">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128">
                                            <defs/>
                                            <path d="M76.1 91.4V71.7a1 1 0 00-.2-.6l-5.7-6.9V15.4a1.1 1.1 0 00-.1-.4.5.5 0 000-.1l-5.8-10a1 1 0 00-1.7 0l-5.9 10a.5.5 0 000 .1 1.2 1.2 0 00-.1.4v48.8l-5.7 6.9a1 1 0 00-.2.6v19.7a1 1 0 001 1h10.6V95a1 1 0 102 0v-2.7h10.8a1 1 0 001-1zm-17.6-75h9.7v4h-9.7zm4.8-9l4.1 7h-8.1zM52.6 72l4-4.8v23h-4zm11.7 18.3V56.7a1 1 0 10-2 0v33.7h-3.8v-68h9.7v68zm9.8 0h-4v-23l4 4.7z"/>
                                            <path d="M59 94.7a1 1 0 00-1 1v10.9a4.2 4.2 0 00-3.3 2.7 1 1 0 00.6 1.3 1 1 0 00.4 0 1 1 0 001-.6 2.2 2.2 0 012.8-1.4 1 1 0 10.7-1.9H60v-11a1 1 0 00-1-1zM68.7 101.2v-5.5a1 1 0 00-2 0v5.5a1 1 0 102 0zM60.2 112.8a1.4 1.4 0 011.3 1 1 1 0 001.9.3 2.5 2.5 0 014.7 1.4 1 1 0 002 0 4.6 4.6 0 00-7.4-3.6 3.4 3.4 0 00-2.5-1.1 1 1 0 000 2zM73.2 109.7a1 1 0 002 0 6.1 6.1 0 00-6.1-6.1 1 1 0 000 2 4.1 4.1 0 014.1 4zM105.2 109a9.8 9.8 0 00-19-3 6.4 6.4 0 00-2.8-.7 6.5 6.5 0 00-6.5 6.5 1 1 0 002 0 4.5 4.5 0 014.5-4.5 4.4 4.4 0 012.8 1 1 1 0 001.6-.6 7.8 7.8 0 0115.4 1.4 1 1 0 002 0zM7.8 123a8 8 0 006.2-.1 1 1 0 00-.8-1.8 6 6 0 01-8-3 6 6 0 012.9-8 9.4 9.4 0 001.5.8 1 1 0 00.4.1 1 1 0 00.4-2 7.5 7.5 0 01-1-13.2 1 1 0 00.1-1.6A4.2 4.2 0 018.1 91a4.2 4.2 0 017.1-3 1 1 0 001 .2 1 1 0 00.6-.6 5.8 5.8 0 0111.3 1.1A7.4 7.4 0 0022 96a1 1 0 002 0 5.4 5.4 0 118.6 4.4 8.9 8.9 0 00-5.4 7 6.6 6.6 0 00-1 0 6.4 6.4 0 00-4.5 1.9 1 1 0 000 1.4 1 1 0 001.4 0 4.4 4.4 0 017.5 3.1 1 1 0 002 0 6.4 6.4 0 00-3.4-5.7 6.8 6.8 0 014.2-5.9 6.8 6.8 0 019.4 5.9 1 1 0 001.4.8 4.8 4.8 0 011.8-.3 5 5 0 015 5 1 1 0 002 0 7 7 0 00-8.4-6.9 8.9 8.9 0 00-8.6-7h-.2a7.4 7.4 0 00-5.7-11.1 7.8 7.8 0 00-14.6-2.9 6.2 6.2 0 00-3.2-.9 6.2 6.2 0 00-5 10 9.4 9.4 0 00-1 13.9 8.1 8.1 0 00-3.3 4A8 8 0 007.8 123zM91.8 119.8a1 1 0 002 0 6.4 6.4 0 00-11-4.5 1 1 0 000 1.4 1 1 0 001.4 0 4.4 4.4 0 013.1-1.3 4.4 4.4 0 014.5 4.4zM125.4 103.3a7.7 7.7 0 00-3.4-6.4 8.1 8.1 0 00.7-3.3A8.3 8.3 0 00107 90a5.8 5.8 0 00-4 10.6 1 1 0 101.1-1.7 3.8 3.8 0 013.2-6.8 1 1 0 001.2-.6 6.3 6.3 0 0112.2 2 6.2 6.2 0 01-1 3.3 1 1 0 000 .8 1 1 0 00.5.6 5.6 5.6 0 011 9.5 8.4 8.4 0 00-2.8-1.6 1 1 0 10-.6 1.8 6.7 6.7 0 01-.4 12.8 1 1 0 00-.7 1.2 1 1 0 001 .8 1.1 1.1 0 00.2 0A8.7 8.7 0 00124 112a8.7 8.7 0 00-1.3-2.8 7.6 7.6 0 002.7-6z"/>
                                        </svg>
                                        <br>Consulte
                                        <br>
                                        <!-- <a href="#">Get started</a> -->
                                    </td>
                                    <td class="price">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128">
                                            <defs/>
                                            <path d="M62.6 103.3a1 1 0 00-1.4 0L44 120.8a1 1 0 000 1.4 1 1 0 00.7.3 1 1 0 00.7-.3l17.3-17.3a1 1 0 000-1.5zM58.7 116.3a1 1 0 00.7-.3l7.2-7.2a1 1 0 00-1.4-1.5l-7.2 7.3a1 1 0 00.7 1.7zM55.2 117.4l-2.8 2.8a1 1 0 00.7 1.7 1 1 0 00.7-.3l2.8-2.8a1 1 0 000-1.4 1 1 0 00-1.4 0zM5.9 84.1a1 1 0 00.7.3 1 1 0 00.7-.3l17.4-17.3a1 1 0 10-1.5-1.4L6 82.7a1 1 0 000 1.4zM13.4 70l7.3-7.2a1 1 0 10-1.5-1.4L12 68.6a1 1 0 00.7 1.7 1 1 0 00.7-.3zM7.8 75.6l2.8-2.8a1 1 0 000-1.4 1 1 0 00-1.4 0l-2.8 2.8a1 1 0 000 1.4 1 1 0 00.7.3 1 1 0 00.7-.3zM68 33.6L27.1 40a1 1 0 00-.5.3L20 46.8a1 1 0 000 1.5l16.6 16.6-4.7 4.7a1 1 0 000 1.5l11.5 11.4-4.6 4.6a1 1 0 00.7 1.7 1 1 0 00.7-.3l4.6-4.6L57 96a1 1 0 001.4 0l4.7-4.7 16.7 16.6a1 1 0 001.4 0l6-6 .5-.5a1 1 0 00.3-.6L94.4 60l20.3-20.3a22.8 22.8 0 006-10.3.6.6 0 000-.2 22 22 0 00.5-3.4l1.1-11.3a8.1 8.1 0 00-8.8-8.8l-11.3 1a22.7 22.7 0 00-3.4.6h-.1a22.8 22.8 0 00-10.4 6zm-23.9 24L28.4 41.8 65.6 36zm-22-10l4.6-4.6 16 16-4.6 4.5zm58.4 58.2l-16-16 4.5-4.5 16 16zm5.6-6.3L70.5 84 92 62.4zm16.3-90.8l11.3-1.1a6.1 6.1 0 016.7 6.7l-1.1 11.3-.2 1.3-18-18 1.3-.2zm-3.6.7l19.8 19.8a21 21 0 01-5.3 9L57.7 94 46.3 82.5 75 53.7a1 1 0 000-1.4 1 1 0 00-1.5 0L45 81.1 34 70.3l10.7-10.6 45-45a21 21 0 019-5.3zM10.9 120.4a3.3 3.3 0 001.4-.3l19.5-9.1A11.2 11.2 0 1017 96.2l-9.1 19.5a3.3 3.3 0 003 4.7zm-1.2-3.9l9-19.5a9.1 9.1 0 016.8-5.2 10.2 10.2 0 011.7-.1 9.2 9.2 0 019 10.8 9.1 9.1 0 01-5.2 6.7l-19.5 9a1.3 1.3 0 01-1.8-1.7z"/>
                                            <path d="M96.2 42.3a10.5 10.5 0 10-7.4-3 10.4 10.4 0 007.4 3zm-6-16.5a8.5 8.5 0 110 12 8.4 8.4 0 010-12z"/>
                                        </svg>
                                        <br>Consulte
                                        <br>
                                        <!-- <a href="#">Get started</a> -->
                                    </td>
                                    <td class="price">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128">
                                            <defs/>
                                            <path d="M38.6 53.2a14.6 14.6 0 0010.6-24.6 1 1 0 00-.7-.3 1.1 1.1 0 00-.7.3l-8.9 8.9-1.7-1.7a1 1 0 00-1.4 1.4l1.7 1.7-9 8.9a1 1 0 000 1.4 14.5 14.5 0 0010.1 4zm9.9-22.4a12.6 12.6 0 01-17.7 17.7z"/>
                                            <path d="M2.7 102.7a1 1 0 00.3.7L24.5 125a1 1 0 00.7.3 1 1 0 00.7-.3l12.4-12.3a.8.8 0 00.4-.5l12.1-12a1 1 0 00.3-.2.9.9 0 00.2-.3l12.3-12.3a1 1 0 000-1.4l-8.4-8.4 1.5-1.5 11.8 11.9 3.7 6.4.1.1a1 1 0 00.1.2l5.1 5.1a1 1 0 00.7.3 1 1 0 00.7-.3L99.7 79a1 1 0 000-1.4l-5-5.1a1 1 0 00-.2-.1h-.1l-6.5-3.8-11.8-11.9 1.4-1.4 8.4 8.3a1 1 0 00.7.3 1 1 0 00.7-.3L125 26a1 1 0 000-1.4L103.5 3a1 1 0 00-1.4 0L89.7 15.4a.8.8 0 00-.4.5L77.2 28a1 1 0 00-.3.2.9.9 0 00-.2.3L64.4 40.8a1 1 0 000 1.4l8.4 8.4-1.5 1.4-11.4-11.4a1 1 0 00-1.4 0l-18 17.9a1 1 0 000 1.4L52 71.3l-1.4 1.4-8.4-8.3a1 1 0 00-1.4 0L3 102a1 1 0 00-.3.7zM29 79l9.3 9.3L27 99.5l-9.4-9.3zm10.7 10.8l9.3 9.3-11.1 11.1-9.3-9.3zm38.7 7.8L74.6 94 94 74.6l3.7 3.7zm-4.9-5.3l-2.8-5 16.8-16.7 5 2.8zm-4-6.5L67 83.6 78.7 72a1 1 0 000-1.4 1 1 0 00-1.4 0L65.7 82.2 46.3 62.8l16.5-16.5 19.4 19.4-1.7 1.7a1 1 0 101.4 1.4l1.7-1.7 2.2 2.2zm20.8-68l9.3 9.3-11 11.1L79 29zm10.7 10.8l9.4 9.3L99.2 49l-9.4-9.3zm1.9-23.4l20 20-11.1 11.2-9.3-9.3 10-10a1 1 0 000-1.4 1 1 0 00-1.5 0l-10 10-9.3-9.3zM77.7 30.3l9.3 9.3-9.6 9.7a1 1 0 000 1.4 1 1 0 001.4 0l9.6-9.7 9.3 9.4-11 11.1-20.2-20zM74.2 52l2 2-1.5 1.4-2-2zm-15-9.2l2.2 2.2L45 61.4l-2.2-2.2zm-5.8 30l1.9 2-1.5 1.4-2-2zm-12-6.2l20 20-11 11.2-9.4-9.3 9.6-9.7a1 1 0 00-1.4-1.4L39.6 87l-9.3-9.3zm-25 25l9.2 9.4-10 10a1 1 0 000 1.4 1 1 0 001.5 0l10-10 9.3 9.3-11.2 11.2-20-20zM43.6 28.2a1 1 0 00-.3-1.4 11.9 11.9 0 00-16.5 16.5 1 1 0 00.9.5 1 1 0 00.5-.2 1 1 0 00.3-1.4 9.9 9.9 0 0113.7-13.7 1 1 0 001.4-.3z"/>
                                            <path d="M38.6 32.3a1 1 0 10.9-1.8 6.7 6.7 0 00-9 9 1 1 0 00.9.5 1 1 0 00.9-1.4 4.7 4.7 0 016.3-6.3z"/>
                                        </svg>
                                        <br>Consulte
                                        <br>
                                        <!-- <a href="#">Get started</a> -->
                                    </td>
                </tr>
                <!-- <tr>
                    <td><span tooltip="Apesar de permitir cadastros ilimitados, a simultaneidade de accesso será definida pelo número de licenças contratadas." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Acessos Simultâneos</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>A partir de 3
                    </td>
                </tr> -->
                <tr>
                    <td><span tooltip="Quantidade de clientes cadastrados dentro do portal de sua empresa." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Cadastros de clientes</td>
                    <!-- <td>1</td> -->
                    <td>Ilimitado</td>
                    <td>Ilimitado</td>
                    <td>Ilimitado</td>
                </tr>
                <tr>
                    <td><span tooltip="Quantidade de usuários cadastrados dentro do portal de sua empresa." flow="right"><i class="fa fa-fw fa-question-circle"></i></span>Cadastro de Usuários</td>
                    <!-- <td>Ilimitado</td> -->
                    <td>Ilimitado</td>
                    <td>Ilimitado</td>
                    <td>Ilimitado</td>
                </tr>
                <tr>
                    <td><span tooltip="Quantidade de itens/peças cadastradas dentro do portal de sua empresa." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Cadastro de Peças</td>
                    <!-- <td>Ilimitado</td> -->
                    <td>Ilimitado</td>
                    <td>Ilimitado</td>
                    <td>Ilimitado</td>
                </tr>
                <tr>
                    <td><span tooltip="Acesso à plataforma de cursos online do QualityManager ®" flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Treinamento EAD</td>
                    <!-- <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td> -->
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>
                <tr>
                    <td><span tooltip="Acesso ao canal de suporte para abertura de chamados para o time de atendimento/suporte." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Suporte via Ticket</td>
                    <!-- <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td> -->
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>
                <tr>
                    <td><span tooltip="Exportação de todos os dados do banco de dados no formato CSV, que possibilita migração para outras plataformas." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Dump - base de dados (CSV)</td>
                    <!-- <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td> -->
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>                
                <tr>
                    <td><span tooltip="Atendimento direto por e-mail com o time de atendimento/suporte." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Suporte via E-mail</td>
                    <!-- <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td> -->
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>

                <tr>
                    <td><span tooltip="Realização de treinamentos exclusivos junto a equipe que utiliza o software." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Treinamento dedicado</td>
                    <!-- <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td> -->
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>                
              
                <tr>
                    <td><span tooltip="Tempo para resposta dos chamados, respeitando as condições dispostas em nossos termos de utilização do sistema" flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Tempo de resposta para suporte</td>
                    <!-- <td>até 48 horas</td> -->
                    <td>até 24 horas úteis</td>
                    <td>até 12 horas úteis</td>
                    <td>até 4 horas úteis</td>
                </tr>
                <tr style="background-color: #f5f5f5;">
                    <td><span tooltip="Atendimento direto por telefone das solicitações através do ramal direto do time de suporte." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Suporte por Telefone</td>
                    <!-- <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td> -->
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <!-- <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td> -->
                </tr>                  
                <tr style="background-color: #f5f5f5;">
                    <td><span tooltip="Gerente dedicado para atendimento e priorização das solicitações." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Gerente de Conta</td>
                    <!-- <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td> -->
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>
                <tr style="background-color: #f5f5f5;">
                    <td><span tooltip="Exportação dos documentos gerados através do sistema no formato de planilhas (Excel)." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Exportação de dados (Excel)</td>
                    <!-- <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td> -->
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr>                
                <!-- <tr style="background-color: #f5f5f5;">
                    <td><span tooltip="Criação de APIs exclusivas para integração com outros sistemas." flow="right"><i class="fa fa-fw fa-question-circle"></i></span> API de Integração</td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr> -->
                <!-- <tr style="background-color: #f5f5f5;">
                    <td><span tooltip="Desenvolvimento de customizações específicas para melhor integração dos usuários**" flow="right"><i class="fa fa-fw fa-question-circle"></i></span> Customizações gratuitas*</td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="close-outline" class="ionclose"></ion-icon></td>
                    <td><ion-icon name="checkmark-outline" class="ioncheck"></ion-icon></td>
                </tr> -->
                <tr>
                    <td></td>
                    <!-- <td class="price">
                        <a [routerLink]="['/assine']">Assine Já</a>
                    </td> -->
                    <td class="price">
                        <a [routerLink]="['/assine']">Teste Já</a>
                    </td>
                    <td class="price">
                        <a [routerLink]="['/assine']">Teste&nbsp;Já</a>
                    </td>
                    <td class="price">
                        <a [routerLink]="['/assine']">Teste Já</a>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <br><br><br>

    <app-footer></app-footer>
</div>