import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  
  public menuOpen: boolean = false;

  constructor(public event: EventService, public router: Router) {}

  login() {
    let url = 'https://QualityManager ®/app/login';
    window.open(url);
  }

  goPage(page: number) {
    if (page >= 1 && page <=5) {
      let route = this.router.url;
      this.router.navigate(['/home']);
      this.event.pageChange.emit(page);

      if (route != '/home') {
        setTimeout(()=>{
          this.event.pageChange.emit(page);
        }, 100)
      }
    } else {

    }
  }

}
